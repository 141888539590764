.footer {
  margin-top: 100px;
  background: linear-gradient(90deg, #df032c,#c00328);
  height: 100px;

  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__descr {
    display: flex;
    align-items: center;
    &-title {
      text-transform: uppercase;
      font-weight: 300;
      color: #ffe400;
      font-size: 2em;
      margin: 0;
      span {
        font-weight: 900;
        color: #ffffff;
      }
    }
  }

}

.logo-footer {
  width: 100px;
}

@media screen and (max-width: 576px){
  .footer {
    margin-top: 50px;
  }
}
