.worktime__grid-right {
  width: 50%;
}

.worktime {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  &__title h3 {
    margin: 0;
    font-weight: 300;
    display: inline-block;
    font-size: 45px;
    color: #00ffb4;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 1.2;
    background-color: #ff447e;
    background-image: linear-gradient(to right, #ffd200 0%, #ff2897 80%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.blocks {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-top: 50px;
}
.block__column {
  width: 100%;
}
.block {
  position: relative;
  height: 0;
}
.block__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px;
}
.block--left {
  padding-bottom: 100%;
  // background-color: #330055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%23281569' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%2304257c' cx='50' cy='100' r='50'/%3E%3Cg fill='%2300348e' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%2300429f' cx='50' cy='200' r='50'/%3E%3Cg fill='%23004fad' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23005db9' cx='50' cy='300' r='50'/%3E%3Cg fill='%23006ac3' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%230076ca' cx='50' cy='400' r='50'/%3E%3Cg fill='%230083cd' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23008fce' cx='50' cy='500' r='50'/%3E%3Cg fill='%23009bcc' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%2300a7c7' cx='50' cy='600' r='50'/%3E%3Cg fill='%2300b3bf' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%2300beb5' cx='50' cy='700' r='50'/%3E%3Cg fill='%2300c9a9' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%2300d49c' cx='50' cy='800' r='50'/%3E%3Cg fill='%2300de8d' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%2300e87e' cx='50' cy='900' r='50'/%3E%3Cg fill='%2331f16e' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%2373F95E' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}
.block--right {
  // background-color: #330055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%233f0058' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%234a005b' cx='50' cy='100' r='50'/%3E%3Cg fill='%2355005d' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23600060' cx='50' cy='200' r='50'/%3E%3Cg fill='%236b0061' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23760063' cx='50' cy='300' r='50'/%3E%3Cg fill='%23810064' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%238c0064' cx='50' cy='400' r='50'/%3E%3Cg fill='%23960065' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a00065' cx='50' cy='500' r='50'/%3E%3Cg fill='%23ab0064' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b50064' cx='50' cy='600' r='50'/%3E%3Cg fill='%23be0063' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23c80061' cx='50' cy='700' r='50'/%3E%3Cg fill='%23d10060' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23da005e' cx='50' cy='800' r='50'/%3E%3Cg fill='%23e20a5b' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23eb1959' cx='50' cy='900' r='50'/%3E%3Cg fill='%23f32556' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23FA2F53' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
  padding-bottom: calc(50% - 8px);
  margin-bottom: 16px;
}
.block--right:nth-child(2){
  // background-color: #330055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23330055' cx='50' cy='0' r='50'/%3E%3Cg fill='%234a0059' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%235f005c' cx='50' cy='100' r='50'/%3E%3Cg fill='%2374005d' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%2388005d' cx='50' cy='200' r='50'/%3E%3Cg fill='%239b005c' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ad005a' cx='50' cy='300' r='50'/%3E%3Cg fill='%23bd0056' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23cd1552' cx='50' cy='400' r='50'/%3E%3Cg fill='%23db2a4c' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e73c46' cx='50' cy='500' r='50'/%3E%3Cg fill='%23f14e3f' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23fa5f37' cx='50' cy='600' r='50'/%3E%3Cg fill='%23ff712e' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ff8424' cx='50' cy='700' r='50'/%3E%3Cg fill='%23ff9616' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ffa801' cx='50' cy='800' r='50'/%3E%3Cg fill='%23ffbb00' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ffcd00' cx='50' cy='900' r='50'/%3E%3Cg fill='%23ffe000' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23FAF204' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}
.text {
  color: #fff;
  font-size: 19px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.text--top {
  text-align: left;
}
.text--middle {
  text-align: center;
  font-size: 54px;
  line-height: 60px;
  font-weight: 900;
}
.text--bottom {
  text-align: right;
}
.count-1:before {
    content: " > ";
}
.count-1:after {
    content: " проектов ";
}
.count-2:after {
    content: " часов ";
}
.count-2:before {
    content: " > ";
}
.count-3:before {
    content: " > ";
}
.count-3:after {
    content: " сайтов ";
}

@media screen and (max-width: 1024px){
  .worktime {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .block--right {
    padding-bottom: calc(50% - 10px);
    margin-bottom: 20px;
  }
  .blocks {
    padding-top: 50px;
  }
}
@media screen and (max-width: 968px){
  .text--middle {
    font-size: 5vw;
  }

}
@media screen and (max-width: 740px){
  .block__content {
    padding: 2vw 2.5vw;
  }
  .text {
    font-size: 2.5vw;
    line-height: 2.7vw;
  }
  .worktime__title h3 {
    font-size: 35px;
    line-height: 35px;
  }
  .text--middle {
    font-size: 5vw;
    line-height: 6vw;
  }
}

@media screen and (max-width: 576px){
  .worktime__title h3 {
    font-size: 30px;
    line-height: 30px;
  }
  .worktime {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .blocks {
    padding-top: 20px;
  }
}

@media screen and (max-width: 500px){
  .worktime__title h3 {
    font-size: 26px;
    line-height: 26px;
  }
}
