.telegram-fixed {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.social-btn {
  display: flex;
  width: 110px;
  padding: 0 10px 0 10px;
  background-color: #ffffff;
  color: #ffffff;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 5px;
  // border: 1px solid #c10328;
}

center a {
  text-decoration: none;
}

.telegram-color {
  background: #ffffff;
}

.telegram-color:hover {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

.icons8-telegram-app {
  width: 30px;
  height: 30px;
  background: url("../img/telegram.svg") 50% 50% no-repeat;
  background-size: 100%;

}

.social-btn p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  color: #ffffff;
}


@mixin gradient-text($gradient, $bg : 'light') {
  @supports(mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg=='light') {
      color: #000;
      background: #fff;
      // mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    }

    @else {
      color: #fff;
      background: #ffffff;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}

section.light {
  background: #ffffff;

  .gradient1 {
    @include gradient-text('linear-gradient(to right, #30CFD0 0%, #330867 100%)', 'light');
  }
}

.gradient1 {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 3px;
}
