@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300);
@import url(https://fonts.googleapis.com/css?family=Arimo:400,700);

$red: #ffffff;
$ease-out: cubic-bezier(.19, .76, .32 ,1);

.logo {
  width: 200px;
}

.hero {
  font-family: Open Sans;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(90deg, #df032c,#c00328);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.hero__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.animation {
  width: 1000px;
  margin: 1em auto;
  position: relative;
}

.arimo {
  font-family: Arimo;
}

.red {
  color: $red;
}

.white {
  color: #ffe400;
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.mask {
  overflow: hidden;
  position: relative;
}

/*********************
* Animation 2
*********************/

@keyframes animation-2-name {
  0% {transform: translate3d(0, 100%, 0);}
  30% {transform: translate3d(0, 100%, 0);}
  60% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-2-title {
  0% {transform: translate3d(0, -100%, 0);}
  30% {transform: translate3d(0, -100%, 0);}
  60% {transform: translate3d(0, 0, 0);}
}

#animation-2 {
  padding-top: 130px;
  text-align: center;

  & > div {
    width: 100%;
    height: 40px;

    div {
      position: absolute;
      width: 100%;
    }
  }

  & > div:first-child {
    font-size: 2.8em;

    div {
      animation: 3s ease-out infinite alternate both animation-2-name;
    }
  }

  & > div:last-child {
    font-size: 24px;
    line-height: 30px;

    div {
      animation: 3s ease-out infinite alternate both animation-2-title;
    }
  }
}


/*********************
* Animation 3
*********************/

@keyframes animation-3-left {
  0% {transform: translate3d(100%, 0, 0);}
  30% {transform: translate3d(100%, 0, 0);}
  60% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-3-right {
  0% {transform: translate3d(-100%, 0, 0);}
  30% {transform: translate3d(-100%, 0, 0);}
  60% {transform: translate3d(0, 0, 0);}
}

#animation-3 {
  display: flex;

  & > div {
    display: inline-block;
    width: 50%;
    height: 1em;
  }

  & > div:first-child {
    font-size: 2.4em;
    text-align: right;
    letter-spacing: 1px;

    div {
      animation: 3.2s ease-out infinite alternate both animation-3-left;
    }
  }

  & > div:last-child {
    font-size: 2.5em;
    line-height: 1;
    letter-spacing: -1px;

    div {
      animation: 3.2s ease-out infinite alternate both animation-3-right;
    }
  }
}


/*********************
* Animation 4
*********************/

@keyframes animation-4-stroke {
  0% {transform: translate3d(0, -100%, 0);}
  30% {transform: translate3d(0, -100%, 0);}
  60% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-4-text {
  0% {transform: translate3d(0, -100%, 0);}
  50% {transform: translate3d(0, -100%, 0);}
  80% {transform: translate3d(0, 0, 0);}
}

#animation-4 {
  & > div {
    display: inline-block;
    vertical-align: middle;

    div {
      animation: 3s ease-out infinite alternate both;
    }
  }

  & > div:first-child {
    font-size: 2.8em;
    line-height: 38px;

    div {
      animation-name: animation-4-text;
    }
  }

  & > div:last-child {

    div {
      width: .38em;
      height: 38px;
      background-color: $red;
      animation-name: animation-4-stroke;
    }
  }
}


/*********************
* Animation 5
*********************/

@keyframes animation-5-bottom-right {
  0% {transform: translate3d(-100%, 96%, 0);}
  8% {transform: translate3d(-100%, 96%, 0);}
  30% {transform: translate3d(0, 96%, 0);}
  38% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-5-top {
  0% {transform: translate3d(100%, 0, 0);}
  38% {transform: translate3d(100%, 0, 0);}
  58% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-5-bottom-left {
  0% {transform: translate3d(-98%, -100%, 0);}
  58% {transform: translate3d(-98%, -100%, 0);}
  64% {transform: translate3d(-98%, 0, 0);}
  84% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-5-text-name {
  0% {transform: translate3d(0, 100%, 0);}
  25% {transform: translate3d(0, 100%, 0);}
  45% {transform: translate3d(0, 0, 0);}
}

@keyframes animation-5-text-title {
  0% {transform: translate3d(0, -100%, 0);}
  35% {transform: translate3d(0, -100%, 0);}
  55% {transform: translate3d(0, 0, 0);}
}

#animation-5 {
  text-align: center;
  height: 3.5em;
  margin-bottom: 100px;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  line {
    stroke-width: 4px;
    stroke: $red;
  }

  div,
  .clip-path {
      animation: 3.1s ease-out infinite alternate both;
  }

  .bottom-right,
  .right {
    clip-path: url(#mask-bottom-right);
  }

  .top {
    clip-path: url(#mask-top);
  }

  .bottom-left,
  .left {
    clip-path: url(#mask-bottom-left);
  }

  #mask-bottom-right > .clip-path {
      animation-name: animation-5-bottom-right;
  }

  #mask-top > .clip-path {
      animation-name: animation-5-top;
  }

  #mask-bottom-left > .clip-path {
      animation-name: animation-5-bottom-left;
  }

  & > div:nth-child(2) {
    font-size: 2em;
    padding-top: .4em;

    div {
      animation-name: animation-5-text-name;
    }
  }

  & > div:nth-child(3) {
    font-size: .9em;
    letter-spacing: .7px;
    margin-top: .2em;

    div {
      animation-name: animation-5-text-title;
    }
  }
}


@media (max-width: 1200px) {
  .animation {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .animation {
    width: 400px;
  }
}

@media (max-width: 500px) {
  .animation {
    width: 370px;
  }
  #animation-4 {
    display: flex;
    justify-content: center;
  }
}
