
.more{
  position: relative;

  &__title h5 {
    text-align: center;
    margin: 0;
    font-weight: 300;
    display: inline-block;
    font-size: 45px;
    color: #00ffb4;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 1.2;
    background-color: #ff447e;
    background-image: linear-gradient(to right, #ffd200 0%, #ff2897 80%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and (max-width: 740px){

  .more__title h5 {
    font-size: 35px;
    line-height: 35px;
  }
}

@media screen and (max-width: 576px){
  .more__title h5 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 500px){
  .more__title h5{
    font-size: 26px;
    line-height: 26px;
  }
}
